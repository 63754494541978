import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
      cursor: "pointer"
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    }
  }));
  

const ProductCard = ({ onClick, image, name }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} onClick={onClick}>
            <CardMedia
              className={classes.media}
              image="https://clairazur.com/site/uploaded/photos/photos_fichier_7.png"
              title={name}
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {name}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ProductCard;