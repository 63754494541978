import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import LoginPage from "./pages/login";
import ProductsPage from "./pages/products";
import PartsPage from "./pages/parts";

function App() {
  return (
    <Router>
       <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/products/:id/parts">
            <PartsPage />
          </Route>
          <Route path="/">
            <ProductsPage />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
