import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { isAuthenticated as isAuthenticatedFunction, authenticatedRequest } from "../../utils/api";
import AuthenticatedUser from "./AuthenticatedUser";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar({ isAuthenticated=isAuthenticatedFunction(), userPicture, username }) {
  const classes = useStyles();
  let history = useHistory();

  const [me, setMe] = useState({});

  //TODO: get /me
  useEffect(() => {
    const load = async () => {
        const meResp = await authenticatedRequest("/me");

        setMe(meResp);
    };

    load();
}, []);

  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
           
          </Typography>
          {isAuthenticated ? <AuthenticatedUser picture={me.picture} name={me.email} /> : <Button color="inherit" onClick={goToLogin}>Login</Button>}
        </Toolbar>
      </AppBar>
    </div>
  );
}