import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import AppBar from "../../components/appBar";
import ProductCard from "./card";

import { authenticatedRequest } from "../../utils/api";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: 30
    },
  }));
  

const Products = () => {
    const [products, setProducts] = useState([]);
    const classes = useStyles();

    let history = useHistory();

    useEffect(() => {
        const load = async () => {
            const products = await authenticatedRequest("/products");
            setProducts(products || []);
        };

        load();
    }, []);
    
    const goToParts = productId => {
        history.push(`/products/${productId}/parts`);
    };

    return (
        <>
            <AppBar />
            <div className={classes.root}>
                <Grid container spacing={10}style={{padding: '24px'}}>
                {products.map(p =>
                    <Grid key={p.ref} itemxs={12} sm={6} md={4} lg={4} xl={3}>
                    <ProductCard name={p.label} onClick={() => goToParts(p.serial_number)}/>
                    </Grid>
                )}
                </Grid>
            </div>
        </>
    );
};

export default Products;