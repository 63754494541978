let AUTH_URL = "http://localhost:3030";
let API_URL = "http://localhost:3031";


if(process.env.NODE_ENV === "production") {
    AUTH_URL = "https://extranet.clairazur.com";
    API_URL = "https://extranet.clairazur.com/api";

}

let token = localStorage.getItem("token");

export const isAuthenticated = () => !!localStorage.getItem("token")

const request = async (url, method = "GET", body, headers = []) => {
    const option = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers
        }
    };

    if(method!=="GET" && method !== "DELETE") {
        option.body = JSON.stringify(body)
    }
    const res = await fetch(url, option);

    if(res.status === 401) {
        throw res;
    }

    return await res.json();
};

export const authenticatedRequest = async (uri, method = "GET", body) => {
    try {
        const res = await request(`${API_URL}${uri}`, method, body, {
            "Authorization": `BEARER ${token}`
        });

        return res;
    } catch(err) {
        console.log(err);
        if(err && err.status === 401) {
            window.location.href = "/login";
        }
    }
};

export const authenticatedOauthRequest = async (uri, method = "GET", body) => {
    try {
        const res = await request(`${AUTH_URL}${uri}`, method, body, {
            "Authorization": `BEARER ${token}`
        });

        return res;
    } catch(err) {
        console.log(err);
        if(err && err.status === 401) {
            window.location.href = "/login";
        }
    }
};

export const authenticate = async (email, password) => {
    const loginUrl = `${AUTH_URL}/oauth/token`;
    const res = await fetch(loginUrl, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email,
            password,
            grant_type: "password",
            client_id: "extranet"
        })
    });

    if(res.status === 401) {
        throw res;
    }

    const jsonRes = await res.json();
    token = jsonRes.token;

    localStorage.setItem("token", token);

    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('grant_type')) {
        //window.urlParams.get('grant_type')
        window.location =
            "/oauth/authorize?grant_type=" + urlParams.get('grant_type')
            + "&redirect_uri=" + urlParams.get('redirect_uri')
            + "&client_id=" + urlParams.get('client_id')
            + "&state=" + urlParams.get('state');
        return;
    }

    window.location = "/";
};