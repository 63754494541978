import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AppBar from "../../components/appBar";
import { authenticatedRequest } from "../../utils/api";

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

const Parts = () => {
    const [parts, setParts] = useState([]);
    let { id } = useParams();
    const classes = useStyles();

    useEffect(() => {
        const load = async () => {
            const parts = await authenticatedRequest(`/products/${id}/parts`);
            setParts(parts);
        };

        load();
    }, []);
        
    return (
        <>
        <AppBar />
        <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Nom</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {parts.map(part => (
                <TableRow key={part.label}>
                    <TableCell component="th" scope="row">
                        {part.label}
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    </>
    );
};

export default Parts;